import React from "react";
import colchonesInfantiles from '../images/colchones-infantiles.jpg';
import colchonesInfantilesMedium from '../images/medium/colchones-infantiles.jpg';
import piscinasInfantilesSmall from '../images/small/colchones-infantiles.jpg';
import separadador from '../images/separador.png';

export default function Grid() {
    return (
        <div className='grid-4'>
            <div className='item-left'>
                <picture>
                    <source srcSet={piscinasInfantilesSmall} media="(max-width: 420px)" />
                    <source srcSet={colchonesInfantilesMedium} media="(max-width: 600px)" />
                    <img src={colchonesInfantiles} className="responsiva" alt="Colchones Infantiles"/>
                </picture>
            </div>
            <div className='item-right'>
                <div className='text'>
                    <p className='title'>COLCHONES <br/>INFANTILES</p>
                    <p className="texto">Pensados para ofrecer un descanso seguro <br/> a los más pequeños. <br/> Colchones con divertidos diseños y formatos <br/> que se adapten a sus necesidades como base <br/> extraíble o borde elevado. <br/> Inflado y desinflado rápido en minutos.</p>
                </div>
            </div>
            <div className='separador-item'>
            <picture>
                <img src={separadador} alt="separador"/>
            </picture>
            </div>
        </div>
    );
}