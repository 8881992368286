import React from "react";
import encuentraIntex from '../images/encuentra-intex.jpg';
import encuentraIntexSmall from '../images/small/encuentra-intex.jpg';
import separadador from '../images/separador-luna.png';

export default function Grid() {
    return (
        <div className='grid'>
            <div className='item'>
                <div className='text'>
                    <p className='title'>HAZLE CASO <br/>A CARLOS SOBERA</p>
                    <p className="texto">para que buscar una cama <br /> no te quite el sueño.</p>
                </div>
            </div>
            <div className='item'>
                <picture>
                    <source srcSet={encuentraIntexSmall} media="(max-width: 420px)" />
                    <img src={encuentraIntex} className="responsiva" alt="Encuentra tu nuevo colchon Intex"/>
                </picture>
                <div className='text-encuentra'>
                    <p className="textoBlanco">Elige la cama de aire INTEX que mejor <br/> se adapte a tus necesidades. Puedes <br/> encontrarlas online y también en <br/> hipermercados, tiendas especializadas <br/> y comercios convencionales.</p>
                </div>
            </div>
            <div className='separador-item'>
            <picture>
                <img src={separadador} alt="separador"/>
            </picture>
            </div>
        </div>
    );
}