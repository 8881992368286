import React,{useState} from 'react'
import cabecera from '../images/cabecera-colchon.jpg';
import cabeceraFull from '../images/full/cabecera-colchon.jpg';
import cabeceraLarge from '../images/large/cabecera-colchon.jpg';
import cabeceraMedium from '../images/medium/cabecera-colchon.jpg';
import cabeceraSmall from '../images/small/cabecera-colchon.jpg';
import ModalVideo from 'react-modal-video'

export default function Header(){
    const [isOpen, setOpen] = useState(false)
    return(
        <div className="header">
            <React.Fragment>
			<ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId="Njgle5g8-K0" onClose={() => setOpen(false)} />
            <a href onClick={()=> setOpen(true)}>
                <picture>
                    <source srcSet={cabeceraSmall} media="(max-width: 420px)" />
                    <source srcSet={cabeceraMedium} media="(max-width: 600px)" />
                    <source srcSet={cabeceraLarge} media="(max-width: 1280px)" />
                    <source srcSet={cabeceraFull} media="(max-width: 1480px)" />
                    <img src={cabecera} className="responsiva" alt="Colchones Intex"/>
                </picture>
            </a>
            </React.Fragment>
        </div>
    );
}

