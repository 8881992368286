import React from "react";
import tecnologiaColchones from '../images/tecnologia-colchones.jpg';
import tecnologiaColchonesFull from '../images/full/tecnologia-colchones.jpg';
import tecnologiaColchonesLarge from '../images/full/tecnologia-colchones.jpg';
import tecnologiaColchonesMedium from '../images/medium/tecnologia-colchones.jpg';
import tecnologiaColchonesSmall from '../images/small/tecnologia-colchones.jpg';

export default function Grid() {
    return (
        <div className="tecnologia-colchones">
                <picture>
                    <source srcSet={tecnologiaColchonesSmall} media="(max-width: 420px)" />
                    <source srcSet={tecnologiaColchonesMedium} media="(max-width: 600px)" />
                    <source srcSet={tecnologiaColchonesLarge} media="(max-width: 1280px)" />
                    <source srcSet={tecnologiaColchonesFull} media="(max-width: 1480px)" />
                    <img src={tecnologiaColchones} className="responsiva" alt="Colchones Intex"/>
                </picture>
        </div>
    );
}