import React from "react";
import colchonesCamping from '../images/colchones-camping.jpg';
import colchonesCampingMedium from '../images/medium/colchones-camping.jpg';
import colchonesCampingSmall from '../images/small/colchones-camping.jpg';

export default function Grid() {
    return (
        <div className='grid-3'>
            <div className='item-left'>
                <div className='text'>
                    <p className='title'>COLCHONES <br/> PARA CAMPING</p>
                    <p className="texto">Cómodos y fáciles de transportar. <br/> Nuestros colchones son ligeros y ofrecen <br/> la mayor resistencia para dormir al aire libre. <br/> Inflado y desinflado rápido en minutos.</p>
                </div>
            </div>
            <div className='item-right'>
                <picture>
                    <source srcSet={colchonesCampingSmall} media="(max-width: 420px)" />
                    <source srcSet={colchonesCampingMedium} media="(max-width: 600px)" />
                    <img src={colchonesCamping} className="responsiva" alt="Colchones Camping"/>
                </picture>
            </div>
        </div>
    );
}