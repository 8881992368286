import React from "react";
import colchonesHogar from '../images/colchones-hogar.jpg';
import colchonesHogarMedium from '../images/medium/colchones-hogar.jpg';
import colchonesHogarSmall from '../images/small/colchones-hogar.jpg';
import separadador from '../images/separador.png';

export default function Grid() {
    return (
        <div className='grid-2'>
            <div className='item-left'>
                <picture>
                    <source srcSet={colchonesHogarSmall} media="(max-width: 420px)" />
                    <source srcSet={colchonesHogarMedium} media="(max-width: 600px)" />
                    <img src={colchonesHogar} className="responsiva" alt="Encuentra tu nueva piscina Intex"/>
                </picture>
            </div>
            <div className='item-right'>
                <div className='text'>
                    <p className='title'>COLCHONES <br/> PARA EL HOGAR</p>
                    <p className="texto">Gran variedad de opciones para elegir <br/> el tamaño, la altura y el nivel de confort <br/> que desees. <br/> Bomba de inflado/desinflado integrada <br/> para tenerlo listo en minutos.</p>
                </div>
            </div>
            <div className='separador-item'>
            <picture>
                <img src={separadador} alt="separador"/>
            </picture>
            </div>
        </div>
    );
}