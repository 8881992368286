import React from "react";
import tecnologia from '../images/tecnologia.jpg';
import garantia from '../images/garantia.jpg';
import confianza from '../images/confianza.jpg';
import tecnologiaSmall from '../images/small/tecnologia.jpg';
import garantiaSmall from '../images/small/garantia.jpg';
import confianzaSmall from '../images/small/confianza.jpg';

export default function Grid() {
    return (
        <div className='grid-5'>
            <h2>Cuando eliges INTEX, eliges:</h2>
            <div className='item-left'>
                <picture>
                    <source srcSet={tecnologiaSmall} media="(max-width: 420px)" />
                    <img src={tecnologia} className="responsiva" alt="Tecnologia Colchon"/>
                </picture>
                <div className='text'>
                    <p className='title'>Tecnología</p>
                    <p className="texto">Un sistema novedoso que <br/> garantiza durabilidad y resistencia.</p>
                </div>
            </div>
            <div className='item-center'>
                <picture>
                    <source srcSet={garantiaSmall} media="(max-width: 420px)" />
                    <img src={garantia} className="responsiva" alt="Garantia Colchon"/>
                </picture>
                <div className='text'>
                    <p className='title'>Garantía</p>
                    <p className="texto">Atención al cliente <br/> rápida y personalizada.</p>
                </div>
            </div>
            <div className='item-right'>
                <picture>
                    <source srcSet={confianzaSmall} media="(max-width: 420px)" />
                    <img src={confianza} className="responsiva" alt="Confianza Colchon"/>
                </picture>
                <div className='text'>
                    <p className='title'>Confianza</p>
                    <p className="texto">Productos de calidad que <br/> proporcionan el mejor descanso</p>
                </div>
            </div>
        </div>
    );
}